<template>
  <div class="tile is-parent is-4">
    <article class="media tile is-child box">
      <div class="media" style="min-height: 80px">
        <div class="media-left is-size-1">
          <figure class="image is-48x48">
            <a v-on:click="showStudentModal()">
              <img
                :src="`data:image/png;base64,${
                  studentDetails?.photo ||
                  'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAALUlEQVR42u3NMQ0AAAgDsE051lHBQdIaaDM5VYFAIBAIBAKBQCAQCAQCwadgAbuSGAE8Ej4FAAAAAElFTkSuQmCC'
                }`"
              />
            </a>
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-4">
            <a
              v-if="getAlerts().reportcard"
              v-on:click="$emit('showReportCard', studentIndex)"
            >
              <Icon
                icon="icon-park:report-xoutline"
                class="flashing"
                style="padding-right: 2px"
              />
            </a>

            {{
              studentDetails?.parents?.PupilGivenName ??
              studentDetails?.basic?.FirstName ??
              studentSummary?.PupilFirstName ??
              "Loading..."
            }}
          </p>

          <p class="subtitle is-6">
            {{
              studentDetails?.basic?.LastName ??
              studentSummary?.PupilLastName ??
              "Loading..."
            }}
          </p>
        </div>

        <div class="media-right">
          <p v-if="studentDetails?.SEND" style="text-align: right">
            &nbsp;
            <a
              v-on:click="showAlert(studentIndex, 'Teaching Advice')"
              v-if="
                studentDetails.SEND && studentDetails.SEND['Teaching Advice']
              "
            >
              <Icon
                icon="pixelarticons:teach"
                width="1.5em"
                height="1.5em"
                :class="reviewNeeded('Teaching Advice') ? 'flashing' : ''"
              />
            </a>

            <a
              v-on:click="showAlert(studentIndex, 'Access Arrangements')"
              v-if="
                studentDetails.SEND &&
                studentDetails.SEND['Access Arrangements']
              "
            >
              <Icon
                icon="bi:ladder"
                width="1.5em"
                height="1.5em"
                :class="reviewNeeded('Access Arrangements') ? 'flashing' : ''"
              />
            </a>

            <a
              v-on:click="
                showAlert(studentIndex, 'SEN Alert', 'SEN Information')
              "
              v-if="
                studentDetails.SEND &&
                studentDetails.SEND['SEN Alert'] === 'TRUE'
              "
            >
              <Icon
                icon="entypo:blackboard"
                width="1.5em"
                height="1.5em"
                :class="reviewNeeded('SEN Alert') ? 'flashing' : ''"
              />
            </a>

            <a
              v-on:click="showAlert(studentIndex, 'SEN Information')"
              v-else-if="
                studentDetails.SEND && studentDetails.SEND['SEN Information']
              "
            >
              <Icon
                icon="entypo:blackboard"
                width="1.5em"
                height="1.5em"
                :class="reviewNeeded('SEN Information') ? 'flashing' : ''"
              />
            </a>

            <a
              v-on:click="
                showAlert(
                  studentIndex,
                  'Medical Alert i',
                  'Medical Conditions Alert'
                )
              "
              v-if="
                studentDetails.SEND &&
                studentDetails.SEND['Medical Alert i'] === 'TRUE'
              "
            >
              <Icon
                icon="healthicons:ambulance"
                width="1.5em"
                height="1.5em"
                :class="reviewNeeded('Medical Alert i') ? 'flashing' : ''"
              />
            </a>
          </p>

          <p v-if="student?.unseenDaybooks" style="text-align: right">
            <Icon
              v-if="student.dayBookAlert && student.dayBookAlert.B"
              icon="healthicons:loudly-crying"
              color="red"
              width="1.5em"
              height="1.5em"
              class="www"
            />
            <Icon
              v-if="student.dayBookAlert && student.dayBookAlert.N"
              icon="healthicons:neutral"
              width="1.5em"
              height="1.5em"
            />
            <Icon
              v-if="student.dayBookAlert && student.dayBookAlert.G"
              icon="healthicons:happy"
              color="green"
              width="1.5em"
              height="1.5em"
            />
          </p>
        </div>
      </div>
      <table class="table is-bordered is-fullwidth has-text-centered">
        <thead>
          <tr class="has-background-primary">
            <th>AM</th>
            <th>PM</th>
            <th v-if="regDetails">Lesson</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span
                v-if="student.attendance?.am_f"
                v-tooltip="student.attendance.am_f"
                >{{ student.attendance.am }}</span
              >
            </td>
            <td>
              <span
                v-if="student.attendance?.pm_f"
                v-tooltip="student.attendance.pm_f"
                >{{ student.attendance.pm }}</span
              >
            </td>
            <td v-if="regDetails">
              {{ regMarks[regDetails["RegisterMarkID"]]["code"] }}
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { getStudent } from "../../classes/student.js";

export default {
  name: "pupil-card",

  props: ["student", "studentIndex", "studentSummary", "regMarks", "wibble"],

  components: { Icon },
  data: function () {
    return {
      loaded: false,
      studentDetails: null,
      regDetails: null,
    };
  },

  mounted() {
    console.log(this.student);
    this.loaded = false;

    console.log({ regDetails: this.regDetails, regMarks: this.regMarks });

    getStudent(this.student).then((x) => {
      this.studentDetails = x;
      console.log(x);
      this.loaded = true;
    });

    this.regDetails =
      this.$store.state.currentRegGroup?.students?.[parseInt(this.student)];
  },

  watch: {
    "$store.state.currentRegGroup": {
      async handler(value) {
        console.log(value);
        this.regDetails = value.students?.[parseInt(this.student)];
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getAlerts() {
      return this.student.ALERT ?? {};
    },
    showAlert(index, field, altfield = null) {
      console.log({ index, field, altfield });
      this.$emit("showAlert", {
        index,
        student: this.studentDetails,
        field,
        altfield,
      });
    },
    showStudentModal() {
      console.log({
        msg: "Emitting to studentModal",
        student: this.studentDetails,
      });
      this.$emit("showStudentModal", this.studentDetails);
    },

    reviewNeeded(index) {
      if (this.$store.state.currentGroupUpdate > 3) {
        return false;
      }

      let x = this.studentDetails.SEND["_" + index + "#changed"];
      let y = this.studentDetails.acked?.SEND?.[index] ?? "0";

      console.log({ student: this.student, index });

      let q = "0" + this.wibble.toString();

      if (q > x) {
        x = q;
      }

      return x > y;
    },
  },
};
</script>
