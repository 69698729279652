<template>
  <div class="container has-background-white-ter">
    <div class="container">
      <div class="tile is-ancestor" v-for="(grp, index) in groups" :key="index">
        <StudentCard
          v-for="(student, studentIndex) in grp"
          :key="student.AcademicRecordID"
          :regDetails="$store.state.currentRegGroup?.students?.[student]"
          :studentIndex="studentIndex + index * 3"
          :studentSummary="
            $store.state.currentGroup?.group?.studentDetails?.[student]
          "
          :student="student.toString()"
          :regMarks="$store.state.currentRegGroup?.marks"
          :wibble="wibble"
          @showAlert="showAlert"
          @showStudentModal="showStudentModal"
          @showReportCard="showReportCard"
        />
      </div>
    </div>

    <modal ref="myModal" @okPressed="ackedSEND" />
    <StudentModal ref="myStudentModal" />
    <RegisterModalSingle ref="myRegisterModalSingle" />

    <ReportCardModal ref="myReportCardModal" />
  </div>
</template>

<script>
import Modal from "Components/Modal";
////import { Icon } from "@iconify/vue2";
import { Student } from "Classes/student";

//import functions from "helpers/functions.js"

import StudentModal from "Components/school/pupilmodal";
import RegisterModalSingle from "Components/school/registersinglemodal";
import ReportCardModal from "Components/school/pupilreportcardmodal.vue";

//import RegisterWithLunch from "Views/School/Timetable/Register/WithLunch.vue";

import StudentCard from "Components/school/pupilcard.vue";

//const ls = require('local-storage')

export default {
  name: "schoolTimetableGroup",

  components: {
    //  Icon,
    Modal,
    StudentModal,
    StudentCard,
    RegisterModalSingle,
    ReportCardModal,
    //RegisterWithLunch,
  },

  data: function () {
    return {
      group: [],
      groupDetails: {},
      loaded: false,
      groupid: null,
      periodID: null,
      regDetails: {},
      regMarks: {},
      gid: null,
      pid: null,
      date: null,
      period: null,
      mode: "showGroup",
      showDayBookMode: "new",
      aaaa: new Date(),
      daybookAlert: false,
      daybooks: [],
      maxDb: 0,
      wibble: 0,
    };
  },
  computed: {
    groups() {
      if (this.$store.state.currentGroupUpdate > 3) {
        return [];
      }

      let group = this.$store.state.currentGroup?.group?.students ?? [];
      if (group.length === 0) {
        return [];
      }
      var final = [];
      let t = 0;
      while (t < group.length) {
        final.push(group.slice(t, t + 3));
        t = t + 3;
      }

      console.log(final);

      console.log({ currentGroup: this.$store.state.currentGroup });

      return final;
    },
  },
  methods: {
    ackedSEND: function (x) {
      let that = this;

      this.$api
        .post("v2/staff/student/info/ack/", true, true, {
          student: x[1],
          type: x[2],
          key: x[3],
        })
        .then((w) => {
          that.$store.commit("acknowledgeFlag", {
            student: x[1],
            type: x[2],
            key: x[3],
            timestamp: w.timestamp,
          });
        });
    },

    changedTimeTable: function (x) {
      console.log(x);

      let ok = true;
      let y = {};
      let instance = this;
      Object.keys(x["data"]).forEach((element) => {
        if (x["data"][element].GroupID != instance.gid) {
          ok = false;
          return;
        }

        y[x["data"][element]["PupilID"]] = x["data"][element];
      });

      if (ok) {
        this.regDetails = y;
      }

      this.mode = "showGroup";
    },

    showAlert: function (params) {
      console.log([params]);

      let student = params.student;

      if (!student) {
        return;
      }

      console.log(student);

      this.$refs.myModal.showModal(
        student.SEND[params.altfield ?? params.field],
        student.name(),
        [params.index, student.PupilID, "SEND", params.field]
      );
    },

    showStudentModal: function (student) {
      this.$refs.myStudentModal.showModal(student);
    },

    showReportCard: function (index) {
      let blob =
        this.$store.state.currentGroup?.group?.students?.[index] ?? null;

      if (!blob) {
        return;
      }

      let student = Object.assign(new Student(), blob);

      this.$refs.myReportCardModal.showModal(student);
    },

    changeRegistrationState: function (index, currentrm, regPeriod) {
      let student = Object.assign(new Student(), this.group[index]);

      this.$refs.myRegisterModalSingle.showModal(
        student,
        currentrm,
        regPeriod,
        this.regMarks
      );
    },
    /*
    registerGroup: function () {
      console.log(this.$store.state.currentGroup?.group?.studentsDetails);
      this.$refs.myRegisterModalGroup.showModal(
        this.$store.state.currentGroup?.group?.studentsDetails,
        this.regDetails,
        this.regMarks,
        this.date,
        this.period,
        this.periodID
      );
    }, */
  },

  created() {},
};
</script>

<style>
www {
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  -webkit-animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    @include transform(scale(0.9));
  }

  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }

  100% {
    @include transform(scale(0.9));
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}
</style>
