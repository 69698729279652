var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tile is-parent is-4"},[_c('article',{staticClass:"media tile is-child box"},[_c('div',{staticClass:"media",staticStyle:{"min-height":"80px"}},[_c('div',{staticClass:"media-left is-size-1"},[_c('figure',{staticClass:"image is-48x48"},[_c('a',{on:{"click":function($event){return _vm.showStudentModal()}}},[_c('img',{attrs:{"src":`data:image/png;base64,${
                _vm.studentDetails?.photo ||
                'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAALUlEQVR42u3NMQ0AAAgDsE051lHBQdIaaDM5VYFAIBAIBAKBQCAQCAQCwadgAbuSGAE8Ej4FAAAAAElFTkSuQmCC'
              }`}})])])]),_c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4"},[(_vm.getAlerts().reportcard)?_c('a',{on:{"click":function($event){return _vm.$emit('showReportCard', _vm.studentIndex)}}},[_c('Icon',{staticClass:"flashing",staticStyle:{"padding-right":"2px"},attrs:{"icon":"icon-park:report-xoutline"}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.studentDetails?.parents?.PupilGivenName ?? _vm.studentDetails?.basic?.FirstName ?? _vm.studentSummary?.PupilFirstName ?? "Loading...")+" ")]),_c('p',{staticClass:"subtitle is-6"},[_vm._v(" "+_vm._s(_vm.studentDetails?.basic?.LastName ?? _vm.studentSummary?.PupilLastName ?? "Loading...")+" ")])]),_c('div',{staticClass:"media-right"},[(_vm.studentDetails?.SEND)?_c('p',{staticStyle:{"text-align":"right"}},[_vm._v("   "),(
              _vm.studentDetails.SEND && _vm.studentDetails.SEND['Teaching Advice']
            )?_c('a',{on:{"click":function($event){return _vm.showAlert(_vm.studentIndex, 'Teaching Advice')}}},[_c('Icon',{class:_vm.reviewNeeded('Teaching Advice') ? 'flashing' : '',attrs:{"icon":"pixelarticons:teach","width":"1.5em","height":"1.5em"}})],1):_vm._e(),(
              _vm.studentDetails.SEND &&
              _vm.studentDetails.SEND['Access Arrangements']
            )?_c('a',{on:{"click":function($event){return _vm.showAlert(_vm.studentIndex, 'Access Arrangements')}}},[_c('Icon',{class:_vm.reviewNeeded('Access Arrangements') ? 'flashing' : '',attrs:{"icon":"bi:ladder","width":"1.5em","height":"1.5em"}})],1):_vm._e(),(
              _vm.studentDetails.SEND &&
              _vm.studentDetails.SEND['SEN Alert'] === 'TRUE'
            )?_c('a',{on:{"click":function($event){return _vm.showAlert(_vm.studentIndex, 'SEN Alert', 'SEN Information')}}},[_c('Icon',{class:_vm.reviewNeeded('SEN Alert') ? 'flashing' : '',attrs:{"icon":"entypo:blackboard","width":"1.5em","height":"1.5em"}})],1):(
              _vm.studentDetails.SEND && _vm.studentDetails.SEND['SEN Information']
            )?_c('a',{on:{"click":function($event){return _vm.showAlert(_vm.studentIndex, 'SEN Information')}}},[_c('Icon',{class:_vm.reviewNeeded('SEN Information') ? 'flashing' : '',attrs:{"icon":"entypo:blackboard","width":"1.5em","height":"1.5em"}})],1):_vm._e(),(
              _vm.studentDetails.SEND &&
              _vm.studentDetails.SEND['Medical Alert i'] === 'TRUE'
            )?_c('a',{on:{"click":function($event){return _vm.showAlert(
                _vm.studentIndex,
                'Medical Alert i',
                'Medical Conditions Alert'
              )}}},[_c('Icon',{class:_vm.reviewNeeded('Medical Alert i') ? 'flashing' : '',attrs:{"icon":"healthicons:ambulance","width":"1.5em","height":"1.5em"}})],1):_vm._e()]):_vm._e(),(_vm.student?.unseenDaybooks)?_c('p',{staticStyle:{"text-align":"right"}},[(_vm.student.dayBookAlert && _vm.student.dayBookAlert.B)?_c('Icon',{staticClass:"www",attrs:{"icon":"healthicons:loudly-crying","color":"red","width":"1.5em","height":"1.5em"}}):_vm._e(),(_vm.student.dayBookAlert && _vm.student.dayBookAlert.N)?_c('Icon',{attrs:{"icon":"healthicons:neutral","width":"1.5em","height":"1.5em"}}):_vm._e(),(_vm.student.dayBookAlert && _vm.student.dayBookAlert.G)?_c('Icon',{attrs:{"icon":"healthicons:happy","color":"green","width":"1.5em","height":"1.5em"}}):_vm._e()],1):_vm._e()])]),_c('table',{staticClass:"table is-bordered is-fullwidth has-text-centered"},[_c('thead',[_c('tr',{staticClass:"has-background-primary"},[_c('th',[_vm._v("AM")]),_c('th',[_vm._v("PM")]),(_vm.regDetails)?_c('th',[_vm._v("Lesson")]):_vm._e()])]),_c('tbody',[_c('tr',[_c('td',[(_vm.student.attendance?.am_f)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.student.attendance.am_f),expression:"student.attendance.am_f"}]},[_vm._v(_vm._s(_vm.student.attendance.am))]):_vm._e()]),_c('td',[(_vm.student.attendance?.pm_f)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.student.attendance.pm_f),expression:"student.attendance.pm_f"}]},[_vm._v(_vm._s(_vm.student.attendance.pm))]):_vm._e()]),(_vm.regDetails)?_c('td',[_vm._v(" "+_vm._s(_vm.regMarks[_vm.regDetails["RegisterMarkID"]]["code"])+" ")]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }